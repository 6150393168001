import React from 'react';
import dayjs from 'dayjs';

// ---------------------------------------------------------------
// フォーム補助
// ---------------------------------------------------------------
export const getSuccessMessageList = (action, messagePrefix) => {
  const messages = [];
  const prefix = messagePrefix ? messagePrefix : '';

  if (action == 'other') {
    messages.push({
      message: `${prefix}が完了しました`,
    });
  }

  if (action == 'create' || action == 'update') {
    const actionName = action == 'create' ? '登録' : '更新';
    messages.push({
      message: `${prefix}の${actionName}が完了しました`,
    });
  }

  return messages;
};

export const getErrorMessageList = (action, messagePrefix) => {
  const messages = [];
  const prefix = messagePrefix ? messagePrefix : '';

  if (action == 'valid') {
    messages.push({
      message:
        '入力値に誤りがあります。指定箇所を修正の上、変更ボタンをクリックして下さい',
    });
  }

  if (action == 'valid-confirm') {
    messages.push({
      message:
        '入力値に誤りがあります。指定箇所を修正の上、確認ボタンをクリックして下さい',
    });
  }

  if (action == 'change-password') {
    messages.push({
      message:
        'パスワードの変更に失敗しました、IDとパスワードが正しいか確認してください',
    });
  }

  if (action == 'other') {
    messages.push({
      message: `${prefix}中にエラーが発生しました`,
    });
  }

  if (action == 'create' || action == 'update' || action == 'draft') {
    let actionName = '更新';
    if (action == 'create') actionName = '登録';
    if (action == 'draft') actionName = '下書き保存';
    messages.push({
      message: `${prefix}の${actionName}中にエラーが発生しました`,
    });
    messages.push({
      message: `もう一度、${actionName}をお試し頂き、同じエラーメッセージが表示される場合は、お問い合わせください。`,
    });
  }

  return messages;
};

export const getPreviewUploadFile = (data) => {
  if (!data || !data.type || !data.name) {
    return <>-</>;
  }

  // アップロードファイルのタイプを取得
  const fileType = data.type;

  // 画像の場合はイメージタグを返却
  if (fileType.match(/^(image\/gif|image\/jpeg|image\/png)$/)) {
    return (
      <img
        data-dz-thumbnail=""
        className="rounded bg-light"
        alt={data.name}
        src={data.preview}
      />
    );
  }

  // ドキュメントファイル以外は、ファイル名を返却
  return <>{data.name}</>;
};

// 値検証: ドロップダウンリスト
export const validDropDown = (selected, required, master, messagePrefix) => {
  if (!required && !selected) {
    return '';
  }

  if (required && !selected) {
    return messagePrefix
      ? `${messagePrefix}を選択してください`
      : '選択してください';
  }

  if (!master.some((entry) => entry.value == selected.value)) {
    return messagePrefix
      ? `${messagePrefix}を正しく選択してください`
      : '正しく選択してください';
  }

  return '';
};

// 値検証: ドロップダウンリスト（複数）
export const validDropDownMultiple = (
  selected,
  required,
  master,
  messagePrefix
) => {
  if (!required && !selected && !Array.isArray(selected)) {
    return '';
  }

  if (required && selected.length < 1) {
    return messagePrefix
      ? `${messagePrefix}を選択してください`
      : '選択してください';
  }

  const invalidValues = selected.filter((item) => {
    return !master.some((entry) => entry.value == item.value) ? true : false;
  });

  if (invalidValues.length > 0) {
    return messagePrefix
      ? `${messagePrefix}を正しく選択してください`
      : '正しく選択してください';
  }

  return '';
};

// 値検証: ドロップダウンリスト（複数・選択項目の追加可能）
export const validDropDownCreatable = (
  selected,
  required,
  master,
  messagePrefix
) => {
  if (!required && !selected && !Array.isArray(selected)) {
    return '';
  }

  if (required && selected.length < 1) {
    return messagePrefix
      ? `${messagePrefix}を選択してください`
      : '選択してください';
  }

  // CreatableSelectの場合、元々のマスター配列にない値が選択される可能性があるため、コメントアウト
  // const invalidValues = selected.filter((item) => {
  //   return !master.some((entry) => entry.value == item.value) ? true : false
  // })
  // if (invalidValues.length > 0) {
  //   return messagePrefix
  //     ? `${messagePrefix}を正しく選択してください`
  //     : '正しく選択してください'
  // }

  return '';
};

// 値検証: ラジオボタン
export const validRadio = (selected, required, master, messagePrefix) => {
  if (!required && !selected) {
    return '';
  }

  if (required && !selected) {
    return messagePrefix
      ? `${messagePrefix}を選択してください`
      : '性別を選択してください';
  }

  if (!master.some((entry) => entry.value == selected.value)) {
    return messagePrefix
      ? `${messagePrefix}を正しく選択してください`
      : '正しく選択してください';
  }

  return '';
};

// 値検証: 日付カレンダー
export const validDate = (selected, required, messagePrefix, isExactCheck) => {
  if (!required && !selected) {
    return '';
  }

  if (required && !selected) {
    return messagePrefix
      ? `${messagePrefix}を選択してください`
      : '選択してください';
  }

  if (selected && !dayjs(selected).isValid()) {
    return messagePrefix
      ? `${messagePrefix}を正しく選択してください`
      : '正しく選択してください';
  }

  //
  // dayjsでは、2月31日とか指定すると、3月2日といった自動変換を行うため
  // ※ とはいえ、momentは、乗り換えしていきたいので、処理でカバー
  //
  if (isExactCheck) {
    const beforeDate = selected.split('/').join('-');
    const afterDate = dayjs(beforeDate).format('YYYY-MM-DD');
    if (beforeDate != afterDate) {
      return messagePrefix
        ? `${messagePrefix}を正しく選択してください`
        : '正しく選択してください';
    }
  }

  return '';
};

// 日付期間選択
export const validDateRange = (data) => {
  const messages = {
    from: '',
    to: '',
  };

  const messagePrefixes = {
    from: data.from.messagePrefix ? data.from.messagePrefix : '',
    to: data.to.messagePrefix ? data.to.messagePrefix : '',
  };

  // 必要なデータが設定されていない場合は処理終了
  if (!data && !data.from && !data.to) {
    return messages;
  }

  // 空値検証: 開始日
  if (!data.from.value && data.from.required) {
    messages.from = `${messagePrefixes.from}を選択してください`;
  }

  // データ形式検証: 開始日
  if (
    data.from.value &&
    messages.from == '' &&
    !dayjs(`${data.from.value} 00:00:00`).isValid()
  ) {
    messages.from = `${messagePrefixes.from}を正しく選択してください`;
  }

  // 空値検証: 終了日
  if (!data.to.value && data.to.required) {
    messages.to = `${messagePrefixes.to}を選択してください`;
  }

  // データ形式検証: 終了日
  if (
    data.to.value &&
    messages.to == '' &&
    !dayjs(`${data.to.value} 00:00:00`).isValid()
  ) {
    messages.to = `${messagePrefixes.to}を正しく選択してください`;
  }

  if (
    data.from.value &&
    data.to.value &&
    messages.from == '' &&
    messages.to == ''
  ) {
    if (
      dayjs(`${data.to.value} 00:00:00`).valueOf() <
      dayjs(`${data.from.value} 00:00:00`).valueOf()
    ) {
      messages.to = `${messagePrefixes.to}を正しく選択してください`;
    }
  }

  return messages;
};

// 値検証: シングルチェックボックス
export const validSingleCheckbox = (selected, required, message) => {
  if (!required && !selected) {
    return '';
  }

  if (required && !selected) {
    return message ? message : '選択してください';
  }

  return '';
};

// 値検証: 書類 (単数アップロード用)
export const validDocumentFile = (values, maxByte, messagePrefix) => {
  // 最大容量の指定がない場合は、自動的に5MBを設定
  if (!maxByte || (Number, parseInt(maxByte) != Number, parseInt(maxByte))) {
    maxByte = 5242880;
    // maxByte = 2097152
  }

  // 最大バイト数をエラーメッセージ用にMB表記に変換
  const maxMegaByte = (maxByte / Math.pow(1024, 2)).toFixed(0);

  // アップロードファイル情報が存在しない場合は処理終了
  if (
    !values ||
    !Array.isArray(values) ||
    !values.length > 0 ||
    !values[0].size ||
    !values[0].type
  ) {
    return '';
  }

  // アップロード可能なファイル形式リスト作成
  const mimeTypes = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/webp',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];

  // ファイル形式のマッチパターン作成
  const regex = new RegExp('(' + mimeTypes.join('|') + ')');

  // アップロードを許可する画像ファイル形式は右で共通: gif, jpeg, png
  // アップロードを許可する画像ファイルの最大バイト数は右で共通: 5242880 => 5MB
  // 画像形式チェック
  if (values[0].type.match(regex) == null) {
    return messagePrefix
      ? `${messagePrefix}は(gif,jpeg,png,webp,pdf,office)のファイルを選択してください`
      : '(gif,jpeg,png,webp,pdf,office)を選択してください';
  }

  if (values[0].size > Number.parseInt(maxByte)) {
    return messagePrefix
      ? `${messagePrefix}は${maxMegaByte}MB以内のファイルを選択してください`
      : 'ファイルは${maxMegaByte}MB以内のファイルを選択してください';
  }

  return '';
};

// 値検証: 画像アップロード (単数アップロード用)
export const validImageFile = (values, maxByte, messagePrefix) => {
  // 最大容量の指定がない場合は、自動的に5MBを設定
  if (!maxByte || (Number, parseInt(maxByte) != Number, parseInt(maxByte))) {
    maxByte = 5242880;
    // maxByte = 2097152
  }

  // 最大バイト数をエラーメッセージ用にMB表記に変換
  const maxMegaByte = (maxByte / Math.pow(1024, 2)).toFixed(0);

  // アップロードファイル情報が存在しない場合は処理終了
  if (
    !values ||
    !Array.isArray(values) ||
    !values.length > 0 ||
    !values[0].size ||
    !values[0].type
  ) {
    return '';
  }

  // アップロードを許可する画像ファイル形式は右で共通: gif, jpeg, png
  // アップロードを許可する画像ファイルの最大バイト数は右で共通: 5242880 => 5MB
  // 画像形式チェック
  if (
    values[0].type.match(/^(image\/gif|image\/jpeg|image\/png|image\/webp)$/) ==
    null
  ) {
    return messagePrefix
      ? `${messagePrefix}は(gif,jpeg,png,webp)のファイルを選択してください`
      : '画像ファイル(gif,jpeg,png)のファイルを選択してください';
  }

  if (values[0].size > Number.parseInt(maxByte)) {
    return messagePrefix
      ? `${messagePrefix}は${maxMegaByte}MB以内のファイルを選択してください`
      : '画像は${maxMegaByte}MB以内のファイルを選択してください';
  }

  return '';
};

// 選択したファイルのプレビュー用情報の取得
export const getSelectedFileInfo = (file) => {
  // プレビュー用情報の初期化
  const data = {
    name: null,
    preview: null,
    isImage: false,
    fileIconClassName: null,
    fileTypeName: null,
    formattedSize: null,
  };

  // ファイル情報が設定されていない場合は処理終了
  if (!file) return data;

  // 基本情報の設定
  data.name = file.name ?? null;
  data.preview = file.preview ?? null;
  data.formattedSize = file.formattedSize ?? null;

  // ファイルタイプ情報が設定されていない場合は処理終了
  if (!file.type) return data;

  // 画像と判定するmimeTypeリストを作成
  const imageMimeTypes = ['image/jpeg', 'image/gif', 'image/png', 'image/webp'];

  // アップロードファイルが画像か？判定
  data.isImage = file.type.match(
    new RegExp('(' + imageMimeTypes.join('|') + ')')
  )
    ? true
    : false;

  // アップロード画像がファイルの場合は処理終了
  if (data.isImage) {
    data.fileTypeName = file.type;
    return data;
  }

  // ドキュメント情報の初期値設定
  data.fileIconClassName = 'mdi mdi-file-cloud';
  data.fileTypeName = 'other';

  if (file.type == 'application/pdf') {
    data.fileIconClassName = 'mdi mdi-file-pdf';
    data.fileTypeName = 'pdf';
  }

  if (
    file.type == 'application/msword' ||
    file.type ==
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    data.fileIconClassName = 'mdi mdi-file-word';
    data.fileTypeName = 'word';
  }

  if (
    file.type == 'application/vnd.ms-excel' ||
    file.type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    data.fileIconClassName = 'mdi mdi-file-excel';
    data.fileTypeName = 'excel';
  }

  if (
    file.type == 'application/vnd.ms-powerpoint' ||
    file.type ==
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ) {
    data.fileIconClassName = 'mdi mdi-file-powerpoint';
    data.fileTypeName = 'powerpoint';
  }

  return data;
};

// APIから取得したファイル情報をフォーム値情報を取得
export const getUploadedFileValue = (value) => {
  // プレビュー用情報の初期化
  const data = {
    id: null,
    name: null,
    path: null,
    preview: null,
    type: null,
    size: null,
    isOriginal: false,
    fileKey: null,
  };

  // ファイル情報が設定されていない場合は処理終了
  if (!value) return data;

  data.id = value.id ?? null;
  data.name = value.originalFileName ?? null;
  data.path = value.originalFileName ?? null;
  data.preview = value.url ?? null;
  data.isOriginal = true;

  if (value.url) {
    const tmp = value.url.split('/');
    data.fileKey = tmp.length > 0 ? tmp[tmp.length - 1] : null;
  }

  const extension = value.url.substring(value.url.lastIndexOf('.') + 1);

  if (extension == 'png') data.type = 'image/png';
  if (extension == 'webp') data.type = 'image/webp';
  if (extension == 'jpg' || extension == 'jpeg') data.type = 'image/jpeg';
  if (extension == 'gif') data.type = 'image/gif';
  if (data.type) return data;

  // ドキュメント情報の初期値設定
  data.type = 'other';

  if (extension == 'pdf') data.type = 'application/pdf';
  if (extension == 'doc') data.type = 'application/msword';
  if (extension == 'docx')
    data.type =
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  if (extension == 'xls') data.type = 'application/vnd.ms-excel';
  if (extension == 'xlsx')
    data.type =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  if (extension == 'ppt') data.type = 'application/vnd.ms-powerpoint';
  if (extension == 'pptx')
    data.type =
      'application/vnd.openxmlformats-officedocument.presentationml.presentation';

  return data;
};

// APIリクエストエラー時のメッセージを抽出
export const getApiErrorMessages = (res) => {
  const ret = {
    status: res && res.status ? res.status : 500,
    errorMessages: [],
  };

  // レスポンスデータが想定外の場合は処理終了: 初期値を返却
  if (!res || !res.data || !res.data.error || !res.data.error.code) return ret;

  // 500エラーの場合は、APIから返却されるエラーメッセージが一定のため、抽出なし
  if (Number.parseInt(res.status) == 500) return ret;

  // 404エラーの場合は、APIから返却されるエラーメッセージが一定のため、抽出なし
  if (Number.parseInt(res.status) == 404) return ret;

  // 401エラーの場合は、APIから返却されるエラーメッセージが一定のため、抽出なし
  if (Number.parseInt(res.status) == 401) return ret;

  // 上記ステータス番号の場合で、レスポンスにerror.detailフィールドがない場合は処理終了
  if (!res.data.error) return ret;

  if (res.data.error.code == 'PJDB_APPLICATION_ERROR') {
    ret.errorMessages.push({
      message: res.data.error.message ?? 'エラーが発生しました',
    });
  } else if (res.data.error.detail) {
    // エラーメッセージを抽出
    const tmp = {
      detail: res.data.error.detail,
      detailKeys: Object.keys(res.data.error.detail),
    };
    tmp.detailKeys.reduce((acc, value) => {
      if (!Array.isArray(tmp.detail[value])) {
        acc.push({
          message: tmp.detail[value],
        });
        return acc;
      }

      tmp.detail[value].map((item) => {
        acc.push({
          message: item,
        });
      });
      return acc;
    }, ret.errorMessages);
  }

  return ret;
};

export const validUrl = (url) => {
  if (!url) return false;
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
