import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import reducer from './reducers';
import rootSaga from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return process?.env?.NODE_ENV != 'development'
      ? getDefaultMiddleware().concat(sagaMiddleware)
      : getDefaultMiddleware().concat(sagaMiddleware).concat(logger);
  },
});

sagaMiddleware.run(rootSaga);

export default store;
